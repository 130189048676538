import type * as Contact from 'types/models/contact.model'
import type * as Brink from 'types/vendors/brink'
import type { Environment } from 'types/environment'
import { useLastRawCart } from './useLastRawCart'
import { convertToBrinkAddress, normalizeBrinkPrice, normalizeCartItems } from '~/lib/brink'
import { DEFAULT_CHECKOUT_INFORMATION } from '~/lib/checkout'
import { getMarket, getStorefront } from '~/lib/storefronts'
import { getShippingMethodLineItem, normalizeAddress, normalizeApplePayTotal, normalizeCartItem, normalizeShippingMethod } from '~/lib/vendors/apple/apple-pay'
import { getItemsCount } from '~/models/cart/getItemsCount'
import { getPriceProducts } from '~/models/cart/getPriceProducts'
import { getDefaultOrder } from '~/models/checkout/order'
import type { ShippingMethod } from '~/types/cart'
import type { CheckoutInformation } from '~/types/checkout'
import { WAREHOUSE_ERROR } from '~/configuration/global.configuration'
import { DEFAULT_TAXES_OBJECT, type TaxForOrder } from '~/models/order/taxes'
import { normalizePriceDiscount } from '~/lib/vendors/brink/normalizePriceDiscount'
import { normalizePostCartOrderToOrder } from '~/lib/vendors/brink/normalizePostCartOrderToOrder'
import _ from '~/server/api/storyblok/[...]'
import type { Order } from '~/types/models/order'
import { cleanCheckoutInformation } from '~/models/checkout/cleanCheckoutInformation'

function isAppleShippingMethod(option: any): option is ApplePayJS.ApplePayLineItem {
  return Reflect.has(option, 'label')
}

export function useApplePayOrder() {
  const order = useState('applePayExpressOrder', () => getDefaultOrder())
  const brinkOrder = useState<Order>('applePayBrinkOrder', () => ({} as Order))
  const shippingMethods = useState<ShippingMethod[]>('applePayShippingMethods', () => [])
  const checkoutInformation = useState<CheckoutInformation>('applePayCheckoutInformation', () => DEFAULT_CHECKOUT_INFORMATION)
  const forceRefetchShipping = useState<boolean>('applePayCartAmountChanged', () => false)
  const taxes = useState<TaxForOrder>('applePayTaxes', () => (DEFAULT_TAXES_OBJECT))

  function setItems(_currency?: Environment.Currency['code']) {
    const currency = _currency || order.value.currency
    const lastRawCart = useLastRawCart().lastRawCart.value

    if (!lastRawCart) {
      console.error('Cart not found')
      return
    }

    const items = normalizeCartItems(lastRawCart.cartItems, currency, lastRawCart)
    const itemsCount = getItemsCount(items)
    const priceProducts = getPriceProducts(items)
    const priceTotal = priceProducts + order.value.priceShipping
    const priceDiscount = normalizePriceDiscount(lastRawCart, currency)

    if (priceProducts !== order.value.priceProducts) {
      forceRefetchShipping.value = true
    }

    order.value = {
      ...order.value,
      items,
      itemsCount,
      priceProducts,
      priceTotal,
      priceDiscount,
    }
  }
  async function setMarket(countryCode?: Environment.CountryCode) {
    if (countryCode) {
      const market = getMarket(countryCode)
      const currency = market.currency.code
      const storefront = getStorefront(market.storefrontCode)
      const currentMarket = order.value.market

      const isDifferentWarehouse = currentMarket.warehouse.region !== market.warehouse.region

      if (isDifferentWarehouse) {
        throw new Error(WAREHOUSE_ERROR)
      }

      const cartStore = useCartStore()
      await cartStore.updateCountryCode(countryCode)

      const isDifferentCurrency = order.value.currency !== currency

      if (isDifferentCurrency) {
        setItems(currency)
        // refreshShippingMethod()
      }

      order.value = {
        ...order.value,
        market,
        currency,
        storefront,
      }

      return
    }

    const storefrontStore = useStorefrontStore()

    order.value = {
      ...order.value,
      market: storefrontStore.currentMarket,
      currency: storefrontStore.currency.code,
      storefront: storefrontStore.current,
    }
  }

  function setAddress(payload: { address: Contact.Address, information: Contact.Information }) {
    const { address, information } = payload

    checkoutInformation.value = {
      address: {
        billing: address,
        shipping: address,
        useShippingForBilling: true,
      },
      contact: information,
    }

    order.value = {
      ...order.value,
      address: {
        billing: address,
        shipping: address,
      },
      contact: {
        firstName: address.firstName,
        lastName: address.lastName,
        email: information.email,
        telephone: information.telephone,
      },
    }
  }

  function setAppleShippingAddress(payload: ApplePayJS.ApplePayPaymentContact) {
    const address = normalizeAddress(payload)
    setAddress(address)
  }

  function setAppleBillingAddress(payload: ApplePayJS.ApplePayPaymentContact) {
    const { address } = normalizeAddress(payload)
    order.value.address.billing = address
    checkoutInformation.value.address.billing = address
    checkoutInformation.value.address.useShippingForBilling = false
  }

  async function setShippingMethod(payload: ShippingMethod | ApplePayJS.ApplePayLineItem) {
    const shippingMethod = resolveShippingMethod(payload)

    if (!shippingMethod) {
      console.error('Shipping method not found')
      return
    }

    const priceTotal = order.value.priceProducts + shippingMethod.price

    order.value = {
      ...order.value,
      shippingMethod: {
        name: shippingMethod.title,
        estimatedDeliveryTime: shippingMethod.deliveryDetails,
      },
      priceShipping: shippingMethod.price,
      priceTotal,
    }
  }

  async function fetchShippingMethods(_countryCode?: Environment.CountryCode) {
    const context = useNuxtApp()
    const countryCode = _countryCode || order.value.market.countryCode
    const market = order.value.market
    const shippingMethods = await context.$cart.listShippingMethods(countryCode, { market }) as ShippingMethod[]
    setShippingMethods(shippingMethods)
    const applePayShippingMethods = shippingMethods.map(normalizeShippingMethod)

    const currentShippingMethod = getShippingMethod()?.shippingMethod
    const isShippingMethodAvailable = currentShippingMethod && shippingMethods.some(method => (method.id === currentShippingMethod.id) && (method.price === currentShippingMethod.price))

    if (!isShippingMethodAvailable) {
      const defaultShippingMethod = shippingMethods[0]
      await setShippingMethod(defaultShippingMethod)
    }

    return {
      shippingMethods,
      applePayShippingMethods,
    }
  }

  function setShippingMethods(data: ShippingMethod[]) {
    shippingMethods.value = data
  }

  function getAppleShippingMethods() {
    return shippingMethods.value.map(normalizeShippingMethod)
  }

  function getShippingMethodByName(name: string) {
    return shippingMethods.value.find(method => method.title === name)
  }

  function resolveShippingMethod(option: ShippingMethod | ApplePayJS.ApplePayLineItem) {
    if (isAppleShippingMethod(option)) {
      return getShippingMethodByName(option.label)
    }

    return option
  }

  function getItems() {
    const items = order.value.items
    const applePayItems = items.map(normalizeCartItem)

    return {
      items,
      applePayItems,
    }
  }

  function getShippingMethod() {
    const shippingMethod = getShippingMethodByName(order.value.shippingMethod.name)

    if (!shippingMethod) {
      return
    }

    const applePayShippingMethod = normalizeShippingMethod(shippingMethod)
    return {
      shippingMethod,
      applePayShippingMethod,
    }
  }

  function getAllAppleLineItems() {
    const { applePayItems } = getItems()
    const applePayShippingMethod = getShippingMethod()?.applePayShippingMethod

    if (!applePayShippingMethod) {
      return applePayItems
    }

    const shippingLineItem = getShippingMethodLineItem(applePayShippingMethod)
    const discountLineItem = order.value.priceDiscount > 0
      ? [{
          label: 'Discount',
          amount: order.value.priceDiscount.toString(),
        }]
      : []
    const taxesLineItems = taxes.value.total > 0
      ? [{
          label: 'Taxes',
          amount: taxes.value.total.toString(),
        }]
      : []

    return [...applePayItems, shippingLineItem, ...taxesLineItems, ...discountLineItem]
  }

  function getTotal() {
    const { priceTotal } = order.value
    const taxAmount = taxes.value.total
    const totalPrice = priceTotal + taxAmount
    const applePayTotal = normalizeApplePayTotal(totalPrice)

    return {
      totalPrice,
      applePayTotal,
    }
  }

  function shouldUpdateShippingMethods(country?: Environment.CountryCode) {
    if (forceRefetchShipping.value) {
      forceRefetchShipping.value = false
      return true
    }

    if (shippingMethods.value.length) {
      return true
    }

    if (!country) {
      return false
    }

    return order.value.market.countryCode !== country
  }

  function isDifferentWarehouse() {
    const cartStore = useCartStore()
    const countryCodeCart = cartStore.state.countryCode

    if (!countryCodeCart) {
      return
    }

    const cartMarket = getMarket(countryCodeCart)
    const applePayMarket = order.value.market

    const isSameWarehouse = cartMarket.warehouse.region === applePayMarket.warehouse.region

    return !isSameWarehouse
  }

  async function updateBrinkCart() {
    const cartStore = useCartStore()
    const shippingMethod = getShippingMethod()?.shippingMethod

    if (!shippingMethod) {
      return
    }

    // Need to add shipping method last, any price updates will wipe out the shipping method from the brink cart
    await cartStore.addShippingMethodToCart(shippingMethod.id)
  }

  function saveOrder(_order: Brink.Order) {
    const orderStatus = _order.status ?? _order.statusLog[_order.statusLog.length - 1]?.status ?? order.value.status
    order.value = {
      ...order.value,
      id: _order.id,
      status: orderStatus,
      createdAt: _order.created ?? order.value.createdAt,
      updatedAt: _order.lastUpdated ?? order.value.updatedAt,
      priceTax: normalizeBrinkPrice(_order.orderTaxAmount, _order.currencyUnit),
      orderNumber: String(_order.reference),
      payment: {
        method: 'ApplePayExpress',
        status: orderStatus,
      },
      externalTaxAmount:
        _order.externalTaxRate && normalizeBrinkPrice(_order.orderTaxAmount, _order.currencyUnit),
    }

    useOrderStore().setSession({
      session: {
        id: _order.id,
        sessionData: 'dummy',
      },
      order: order.value,
    })

    forceRefetchShipping.value = true
  }

  function getCleanCheckoutInformation() {
    return cleanCheckoutInformation(checkoutInformation.value)
  }

  async function createOrder(options?: { useDummyData?: boolean }) {
    const context = useNuxtApp()
    const orderApplePay = useApplePayOrder()
    const _checkoutInformation = options?.useDummyData ? getCleanCheckoutInformation() : checkoutInformation.value
    const contact = _checkoutInformation.contact
    const shippingAddress = convertToBrinkAddress(_checkoutInformation.address.shipping, contact)
    const billingAddress = convertToBrinkAddress(_checkoutInformation.address.billing, contact)
    const email = contact.email
    const orderBody: Brink.AdyenCartToAdyenOrderBody = {
      shippingAddress,
      billingAddress,
      email,
      returnUrl: location.href,
    }

    await orderApplePay.updateBrinkCart()
    const orderResponse = await context.$payments.createCartOrders(orderBody)

    const cart = useCartStore().state

    const _order = normalizePostCartOrderToOrder(orderResponse, cart, orderApplePay.getShippingMethod()?.shippingMethod)

    order.value = _order
    brinkOrder.value = _order
    if (_order.externalTaxAmount) {
      taxes.value = { total: _order.externalTaxAmount, lineItems: [] }
    }

    return _order
  }

  function getBrinkShippingAddress() {
    const _checkoutInformation = checkoutInformation.value
    const contact = _checkoutInformation.contact
    const shippingAddress: Brink.OverrideAddress = convertToBrinkAddress(_checkoutInformation.address.shipping, contact)
    delete shippingAddress.country
    return shippingAddress
  }

  function getNormalizedShippingAddress() {
    const _checkoutInformation = checkoutInformation.value
    return _checkoutInformation.address.shipping
  }

  function getBrinkBillingAddress() {
    const _checkoutInformation = checkoutInformation.value
    const contact = _checkoutInformation.contact
    const billingAddress: Brink.OverrideAddress = convertToBrinkAddress(_checkoutInformation.address.billing, contact)
    delete billingAddress.country
    return billingAddress
  }

  function getNormalizedBillingAddress() {
    const _checkoutInformation = checkoutInformation.value
    return _checkoutInformation.address.billing
  }

  function getBrinkEmail() {
    const _checkoutInformation = checkoutInformation.value
    const contact = _checkoutInformation.contact
    return contact.email
  }

  function getOrderID() {
    return order.value.id
  }

  return {
    order,
    brinkOrder,
    checkoutInformation,

    getItems,
    getShippingMethod,
    getTotal,
    getAllAppleLineItems,
    shouldUpdateShippingMethods,
    isDifferentWarehouse,

    setItems,
    setMarket,
    setAddress,
    getAppleShippingMethods,
    setAppleShippingAddress,
    setAppleBillingAddress,
    setShippingMethod,
    setShippingMethods,
    getBrinkShippingAddress,
    getBrinkBillingAddress,
    getNormalizedShippingAddress,
    getNormalizedBillingAddress,
    getBrinkEmail,
    getOrderID,

    updateBrinkCart,
    saveOrder,
    createOrder,

    fetchShippingMethods,

  }
}
