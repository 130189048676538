import type { PaymentAmount } from '@adyen/adyen-web/dist/types/types'
import type { Environment } from 'types/environment'
import type * as Helpers from 'types/helpers'

export function getAdyenEnvironment(storeCode: Environment.CountryCode, adyenEnvironmentConfig = ''): 'test' | 'live' | 'live-us' | 'live-au' | 'live-apse' | 'live-in' {
  const env = adyenEnvironmentConfig
  if (!env)
    return 'test'
  if (env !== 'live')
    return 'test'
  if (storeCode === 'US')
    return 'live-us'
  // if (storeCode === 'AU')
  //   return 'live-au' // For Paypal and Finances

  return env
}

export function getAdyenClientKey(storeCode: Environment.StorefrontCode, config: {
  us: string
  default: string
}): string {
  if (storeCode === 'us')
    return config.us || config.default

  return config.default
}

export function getAdyenApiKey(storeCode: Environment.StorefrontCode, config: {
  us: string
  default: string
}): string {
  if (storeCode === 'us')
    return config.us || config.default

  return config.default
}

// ! Todo: Extend with provider info
/**
 * @link https://docs.adyen.com/online-payments/web-drop-in#drop-in-error-handling
 */
export function onAdyenSessionError(error: {
  name: 'CANCEL' | 'NETWORK_ERROR' | string
  message: string | 'The session has expired.'
  columnNumber: number
  fileName: string
  lineNumber: number
  [key: string]: string | boolean | number
}) {
  const checkoutStore = useCheckoutStore()
  const gtm = useGTM()

  console.error('Adyen session error', error)

  if (error.name === 'NETWORK_ERROR') {
    try {
      checkoutStore.loading = true
      checkoutStore.reset()
    }
    catch (e) {
      console.error('Failed to restore cart after failed payment', e)
    }
    finally {
      checkoutStore.loading = false
    }
  }

  if (error.name === 'CANCEL')
    gtm.pushPaymentCancelEvent()
}

export function getAdyenErrorMessage(errorCode: Adyen.ResultCode, $t: Helpers.TranslateHelper) {
  switch (errorCode) {
    case 'Refused':
      return $t('refused')
    case 'Cancelled':
      return $t('cancelled')

    default:
      return ''
  }
}
