<script lang="ts" setup>
import { getDisplayVariant } from '~/lib/product'
import type { Product } from '~/types/models/product'

interface Props {
  product: Product
  styleVariant: 'listing' | 'detail'
}

const props = withDefaults(defineProps<Props>(), {
  styleVariant: 'listing',
})
const selectedVariant = computed(() => getDisplayVariant(props.product))
const storefrontStore = useStorefrontStore()

const { currency } = storeToRefs(storefrontStore)
const currentWarehouse = computed(() => storefrontStore.current.warehouse.region.toLowerCase())

const { isDiscounted, originalPrice, finalPrice } = useProductPrice(currency, selectedVariant)

const discountValue = computed(
  () => props.product.badges.find(badge => badge.name === 'discount')?.value,
)

const isPriceConsistent = computed(() => {
  const prices = props.product.variants
    .filter(variant => variant.stockStatus !== 'no-stock')
    .map(variant => variant.price.final)
  return [...new Set(prices)].length === 1
})

const showDiscountedPriceAsFinal = computed(() => {
  const isSeasonalClearance = props.product.seasonalClearance?.[currentWarehouse.value]
  return isSeasonalClearance && isDiscounted
})

const priceClass = computed(() => {
  return {
    'price-detail': props.styleVariant === 'detail',
    'price-listing': props.styleVariant === 'listing',
  }
})
</script>

<template>
  <div class="price-wrapper">
    <div v-if="showDiscountedPriceAsFinal" :class="[priceClass]">
      <span>
        {{ $price(finalPrice, true) }}
      </span>
    </div>
    <template v-else>
      <div v-if="isDiscounted" :class="[priceClass]">
        <span class="visually-hidden">{{ $t('originalPrice') }}</span>
        <s class="grid-original-price" data-test="product-price-full">
          {{ $price(originalPrice, true) }}
        </s>
        <span class="visually-hidden">{{ $t('discountedPrice') }}</span>
        <span class="discounted final grid-final-price" data-test="product-price-discounted">
          {{ $price(finalPrice, true) }}
        </span>
        <ProductDiscountChip class="grid-discount-chip" :is-discounted="isDiscounted" :discount-value="discountValue" />
      </div>
      <span v-if="!isDiscounted && !isPriceConsistent" :class="[priceClass]">
        {{ $t('fromPrice', { price: $price(finalPrice, true) }) }}
      </span>
      <span v-else-if="!isDiscounted" :class="[priceClass]">
        {{ $price(finalPrice, true) }}
      </span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.price-wrapper {
  text-wrap: wrap;
  display: flex;
  padding-bottom: 1.8rem;

  @media (min-width: $phone-bigger) {
    padding-bottom: unset;
  }

  .discounted {
    &.final {
      color: var(--red);
    }
  }
}

.grid-original-price {
  grid-area: original-price;
}

.grid-final-price {
  grid-area: final-price;
}

.grid-discount-chip {
  grid-area: discount-chip;
}

.price-listing {
  display: grid;
  column-gap: 0.4rem;
  grid-template-areas:
    'original-price discount-chip'
    'final-price discount-chip';

  @media (min-width: $phone-bigger) {
    column-gap: 1.6rem;
  }

  @include body1;
}

.price-detail {
  display: grid;
  grid-template-areas:
    'final-price original-price discount-chip';
  place-items: center;

  font-size: 2rem;
  gap: 1.2rem;

  @media (min-width: $tablet) {
    gap: 1.6rem;
  }
}
</style>
