import type { AxiosResponse } from 'axios'
import Axios from 'axios'

import { BRINK_API_ORDER_URL, BRINK_API_STOCK_URL } from 'configuration/global.configuration'
import type { CartRepository } from 'contracts/CartRepositoryContract'
import { createRepositoryLogger } from 'lib/repositories'
import { isCartSessionResponse } from 'lib/checkout'
import { isNotUndefined } from 'lib/helpers/isNotUndefined'
import {
  getBrinkCartItemsPrice,
  getDiscountCodeFromDiscounts,
  hasFreeShippingFromDiscount,
  isBrinkError,
  normalizeBrinkPrice,
  normalizeCartItems,
} from 'lib/brink'

import type * as Brink from 'types/vendors/brink'
import { BrinkCartErrorCodes } from 'types/vendors/brink'
import { isValidEnvironmentCountryCode } from 'types/guards/storefront'
import type { ShippingMethod } from 'types/cart'
import { FetchError } from 'ofetch'
import { getItemsCount } from '~/models/cart/getItemsCount'
import { useLastRawCart } from '~/composables/useLastRawCart'

const name = 'BrinkCartRepository'

function throwResetError(error: any) {
  if (
    isBrinkError(error)
    && (error.code === BrinkCartErrorCodes.UNAUTHORIZED
      || error.code === BrinkCartErrorCodes.CART_CLOSED
      || error.code === BrinkCartErrorCodes.CART_NOT_FOUND)
  ) {
    throw new EvalError('RESET')
  }
}

export const BrinkCartRepository: CartRepository = ({ baseURL }) => {
  let api = Axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  api = createRepositoryLogger(name, api)

  const logger = useServerLogging('brink_cart_repository')

  const apiFetch = $fetch.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    async onRequest({ request, options }) {
      if (typeof request === 'string' && !request.includes(`/${BRINK_API_STOCK_URL}/product`)) {
        const cartStore = useCartStore()
        if (process.client && cartStore.cartToken) {
          options.headers = {
            ...options.headers,
            Authorization: cartStore.cartToken,
          }
        }
        else {
          const { currentMarket } = useStorefrontStore()
          options.body = typeof options.body === 'object' ? options.body : {}
          options.body = {
            ...options.body,
            store: {
              countryCode: currentMarket.countryCode,
              languageCode: currentMarket.language,
            },
          }
          options.method = 'POST'
        }
      }
    },
    async onResponse({ response }) {
      if (process.client && isCartSessionResponse(response._data)) {
        const cartStore = useCartStore()
        cartStore.setCartToken(response._data.jwtToken)
      }
    },
  })

  const repository: ReturnType<CartRepository> = {
    _name: name,
    api,
    recentRequests: [],
    normalizers: {
      normalizeCart(cart: Brink.Cart) {
        const { currency } = useStorefrontStore()
        useLastRawCart().lastRawCart.value = cart

        const getSelectedShippingMethod = () => {
          const selected = cart.cartItems.find(item => item.type === 'shippingOption')
          if (!selected)
            return undefined

          return this.normalizeShippingMethod(selected)
        }

        const { countryCode } = cart.store

        if (countryCode && !isValidEnvironmentCountryCode(countryCode))
          throw new EvalError(`Invalid country code passed: ${countryCode}`)

        const shippingMethod = getSelectedShippingMethod()
        const lastRawCart = useLastRawCart().lastRawCart.value

        const cartItems = normalizeCartItems(cart.cartItems, currency.code, lastRawCart)
        const discountCode = (cart.discounts && getDiscountCodeFromDiscounts(cart.discounts)) ?? ''

        const currencyCode = cart.store.currencyUnit || currency.code
        const isFreeShippingDiscountCodeApplied = cart.discounts
          ? hasFreeShippingFromDiscount(cart.discounts)
          : false

        return {
          cartId: cart.id,
          items: cartItems,
          messages: [],
          shippingMethod,
          itemsCount: getItemsCount(cartItems),
          priceTax: normalizeBrinkPrice(
            (cart.store.taxPercentage ?? 0 / 100) * cart.totalPrice,
            currencyCode,
          ),
          priceProducts: getBrinkCartItemsPrice(cartItems),
          priceShipping: shippingMethod?.price ?? 0,
          priceDiscount:
            normalizeBrinkPrice(cart.totalDiscountAmount, currencyCode)
            - (shippingMethod?.discountAmount ?? 0),
          priceTotal: normalizeBrinkPrice(cart.totalPriceWithDiscount, currencyCode),
          discountCode,
          countryCode,
          isFreeShippingDiscountCodeApplied,
        }
      },

      // @ts-expect-error - TS does not recognize the error variable as not returning a title
      normalizeShippingMethod(method: Brink.ShippingOption, options) {
        const storefrontStore = useStorefrontStore()
        const currentMarket = options?.market ?? storefrontStore.currentMarket

        const brinkLocale = currentMarket.locale.iso

        const discountAmount = method.discount[currentMarket.currency.code]
        const price = method.price[currentMarket.currency.code] - (discountAmount ?? 0)

        const deliveryDetails = method.attribute.description?.[brinkLocale]
        const title
          = method.attribute.displayName?.[brinkLocale] ?? method.attribute.displayName?.en

        const errors = []

        if (!price && price !== 0)
          errors.push('price')

        if (!deliveryDetails)
          errors.push('description')

        if (!title)
          errors.push('title')

        if (errors.length) {
          errors.forEach((error) => {
            console.error(`Missing ${error} for ${method.id} ${brinkLocale}`)
          })

          return undefined
        }

        return {
          id: method.id,
          title,
          available: Boolean(method.active),
          errorMessage: undefined,
          price: normalizeBrinkPrice(price, currentMarket.currency.code),
          discountAmount: discountAmount
            ? normalizeBrinkPrice(discountAmount, currentMarket.currency.code)
            : 0,
          deliveryDetails,
          logo: method.imageUrl,
        }
      },

      normalizeShippingMethods(values: Brink.ShippingOption[], options): ShippingMethod[] {
        return values
          .map(option => this.normalizeShippingMethod(option, options))
          .filter(isNotUndefined)
          .sort((a, b) => a.price - b.price)
      },
    },

    async fetchCart() {
      const cartStore = useCartStore()
      try {
        const { data: cart } = await this.api.get<Brink.Cart>(`/${BRINK_API_ORDER_URL}/carts`)

        if (cart.state === 'CLOSED') {
          logger.logWarning({
            message: 'Cart is closed',
            code: 'fetch_cart_closed',
            data: JSON.stringify(cart),
          })
          cartStore.removeCartToken()
          cartStore.$reset()
        }

        return this.normalizers.normalizeCart(cart)
      }
      catch (error) {
        const cart = useLastRawCart().lastRawCart.value
        logger.logInfo({
          message: 'Failed to fetch cart',
          code: 'fetch_cart_failed',
          data: JSON.stringify(`${error} \n\n ${cart}`),
        })

        if (Axios.isAxiosError(error) && error.response) {
          throwResetError(error.response?.data)
          return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
      }
    },

    async isProductInStock({ barcode }) {
      try {
        const { currentMarketCountryCode } = useStorefrontStore()

        const { data } = await this.api.get<Brink.ProductStock>(
          `/${BRINK_API_STOCK_URL}/products`,
          {
            params: {
              storeId: currentMarketCountryCode,
              productIds: barcode,
            },
          },
        )
        if (data.products.length < 1)
          return Promise.reject(new EvalError('Missing product in stock response'))

        return Promise.resolve(Boolean(data.products[0]?.isAvailable))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async addProduct(product) {
      return this.addProducts([product])
    },

    async addProducts(products) {
      try {
        const { cart } = await apiFetch<Brink.CartSessionResponse>(
          `/${BRINK_API_ORDER_URL}/carts`,
          {
            body: { products },
            method: 'PUT',
          },
        )

        return Promise.resolve(this.normalizers.normalizeCart(cart))
      }
      catch (e) {
        console.error(e)
        if (e instanceof FetchError)
          throwResetError(e.data)

        return Promise.reject(e)
      }
    },

    async removeProduct(barcode) {
      try {
        const {
          data: { cart },
        } = await this.api.put<Brink.CartSessionResponse>(`/${BRINK_API_ORDER_URL}/carts`, {
          products: [
            {
              id: barcode,
              quantity: 0,
            },
          ],
        })
        return Promise.resolve(this.normalizers.normalizeCart(cart))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async updateProduct(product) {
      return this.addProducts([product])
    },

    async listShippingMethods(code, options) {
      const cartStore = useCartStore()
      try {
        if (!cartStore.cartToken)
          throw new EvalError('Cart token is missing')

        const { data } = await this.api.get<Brink.ShippingOption[]>(
          `/${BRINK_API_ORDER_URL}/shipments/${code.toUpperCase()}`,
        )

        if (!data.length)
          console.error('No shipping methods found. Please configure them in admin.')

        return Promise.resolve(this.normalizers.normalizeShippingMethods(data, options))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async selectShippingMethod(id) {
      try {
        const {
          data: { cart },
        } = await this.api.put<Brink.CartSessionResponse>(`/${BRINK_API_ORDER_URL}/carts`, {
          products: [{ id, quantity: 1 }],
        })
        return Promise.resolve(this.normalizers.normalizeCart(cart))
      }
      catch (e) {
        return Promise.reject(e)
      }
    },

    async applyDiscountCode(code) {
      try {
        const {
          data: { cart },
        } = await this.api.put<Brink.CartSessionResponse>(`/${BRINK_API_ORDER_URL}/carts/`, {
          code,
        })

        const discountCode = cart.discounts && getDiscountCodeFromDiscounts(cart.discounts)

        if (!discountCode)
          throw new Error('No discount code found')

        return Promise.resolve(this.normalizers.normalizeCart(cart))
      }
      catch (error) {
        return Promise.reject(error)
      }
    },

    async updateCountryCode(countryObject) {
      const { state } = useCartStore()
      let countryCode = countryObject?.countryCode
      let languageCode = countryObject?.languageCode

      if (!countryObject) {
        const { currentMarket, currentMarketCountryCode } = useStorefrontStore()
        countryCode = currentMarketCountryCode
        languageCode = currentMarket.language
      }

      if (state.countryCode === countryCode)
        return Promise.resolve()

      try {
        const {
          data: { cart },
        } = await this.api.put<Brink.CartSessionResponse>(`/${BRINK_API_ORDER_URL}/carts`, {
          store: {
            countryCode,
            languageCode,
          },
        })

        return this.normalizers.normalizeCart(cart)
      }
      catch (error) {
        return Promise.reject(error)
      }
    },
  }

  repository.api.interceptors.request.use((config) => {
    const cartStore = useCartStore()
    if (
      process.client
      && !config.url?.includes(`/${BRINK_API_STOCK_URL}/product`)
      && cartStore.cartToken
    ) {
      config.headers.Authorization = cartStore.cartToken
    }

    repository.recentRequests.push({
      method: config.method?.toUpperCase(),
      url: `${baseURL}${Axios.getUri(config)}`,
    })

    return config
  })

  repository.api.interceptors.response.use(
    (config: AxiosResponse<Brink.Cart | Brink.CartSessionResponse>) => {
      const cartStore = useCartStore()
      if (process.client && isCartSessionResponse(config.data))
        cartStore.setCartToken(config.data.jwtToken)

      return config
    },
  )

  return repository
}
